<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="subscription.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
              subscription.organization.id = organizationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PACKAGES)"
    >
      <base-input
        :label="`${$t('COMMON.PACKAGE')} (*)`"
        :placeholder="$t('COMMON.PACKAGE')"
      >
        <package-selector
          :pack="subscription.package.id"
          :filterable="true"
          :showAll="false"
          @packageChanged="
            (packageId) => {
              subscription.package.id = packageId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.package" />
    </div>

    <div class="form-wrapper">
      <base-input :label="`${$t('SUBSCRIPTIONS.START_TIME')} (*)`">
        <flat-picker
          :config="{
            allowInput: true,
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="subscription.start_time"
          @on-change="
            () => {
              onFormChanged();
            }
          "
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.start_time" />
    </div>

    <div class="form-wrapper">
      <base-input :label="`${$t('SUBSCRIPTIONS.END_TIME')} (*)`">
        <flat-picker
          :config="{
            allowInput: true,
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="subscription.end_time"
          @on-change="
            () => {
              onFormChanged();
            }
          "
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.end_time" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          subscription.id
            ? $t("SUBSCRIPTIONS.EDIT_SUBSCRIPTION")
            : $t("SUBSCRIPTIONS.ADD_SUBSCRIPTION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PackageSelector from "@/components/PackageSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    PackageSelector,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["subscriptionData", "formErrors", "loading"],

  data() {
    return {
      subscription: { ...this.subscriptionData },
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.subscription.start_time = moment(
        this.subscription.start_time
      ).toISOString();
      this.subscription.end_time = moment(
        this.subscription.end_time
      ).toISOString();
      this.$emit("subscriptionSubmitted", this.subscription);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    subscriptionData(subscriptionData) {
      if (subscriptionData) {
        this.subscription = {
          ...this.subscription,
          ...cloneDeep(subscriptionData),
        };
      }
    },
  },
};
</script>
