<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">
      {{ `${subscription.organization.name} - ${subscription.package.name}` }}
      <span v-if="isSubscriptionExpired" class="text-danger">
        <i class="ni ni-fat-remove"></i>
      </span>
      <span v-if="!isSubscriptionExpired" class="text-success">
        <i class="ni ni-check-bold"></i>
      </span>
    </h3>
    <div class="col-12">
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="subscription.organization" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PACKAGES)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.PACKAGE") }}</dt>
        <dd class="col-sm-8">
          <router-link
            v-if="subscription.package"
            :to="$objectViewRoute(subscription.package)"
          >
            {{ `${subscription.package.name}` }}
          </router-link>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("SUBSCRIPTIONS.START_TIME") }}</dt>
        <dd class="col-sm-8">
          {{ subscription.start_time | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("SUBSCRIPTIONS.END_TIME") }}</dt>
        <dd class="col-sm-8">
          {{ subscription.end_time | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ subscription.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ subscription.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "subscription-view-global",

  components: {},

  props: ["subscription"],

  data() {
    return {};
  },

  computed: {
    isSubscriptionExpired() {
      return moment(this.subscription.end_time) < moment();
    },
  },

  created() {},

  methods: {},

  mounted() {},

  watch: {
    subscription(subscription) {},
  },
};
</script>
