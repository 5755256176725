<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!subscription">
      <span class="loader"></span>
    </span>
    <subscription-form
      v-if="subscription"
      :loading="loading"
      :subscriptionData="subscription"
      :formErrors="formErrors"
      @subscriptionSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import SubscriptionForm from "../partials/SubscriptionForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { SubscriptionForm },

  mixins: [alertLeave],

  props: {
    subscriptionId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      subscription: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("subscriptions/get", this.subscriptionId);
        this.subscription = this.$store.getters["subscriptions/subscription"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(subscription) {
      this.loading = true;
      const subscriptionData = cloneDeep(subscription);

      try {
        await this.$store.dispatch("subscriptions/update", subscriptionData);
        this.$notify({
          type: "success",
          message: this.$t("SUBSCRIPTIONS.SUBSCRIPTION_UPDATED"),
        });
        const subscription = await this.$store.getters[
          "subscriptions/subscription"
        ];
        this.$emit("onViewSubscription", subscription, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
