export default {
  type: "subscriptions",
  start_time: null,
  end_time: null,
  relationshipNames: ["organization", "package"],
  organization: {
    type: "organizations",
    id: null,
  },
  package: {
    type: "packages",
    id: null,
  },
};
